<template>
  <div class="legal">
    <div class="background"></div>
    <!-- <div class="background"></div> -->
    <v-container>
      <v-row class="mb-10 justify-center">
        <v-col class="content_style px-10 py-11" cols="12" sm="8">
          <p class="titleName text-h3 mb-10 text-center titleName">
            {{ $t('verify.p2')}}
          </p>
          <h2> {{ $t('verify.p3')}}</h2>
          <h3>
           {{ $t('verify.p4')}}
          </h3>
          <img class="code" src="/static/img/verify/code.png?v=20240407" alt="" />
          <h3>
           {{ $t('verify.p5')}}
          </h3>
          <pre v-text="$t('verify.p6')" />

          <h3> {{ $t('verify.p7')}}</h3>
          <pre v-text="$t('verify.p8')" />
          <h3>
            {{$t('verify.p9')}}<br />
            {{$t('verify.p10')}}<br />
          </h3>
          <pre v-text="$t('verify.p11')" />
          <h3>{{$t('verify.p12')}}</h3>
          <p>
            {{$t('verify.p13')}}
          </p>
          <h3>{{$t('verify.p14')}}</h3>
          <p>
            {{$t('verify.p15')}}
          </p>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss" scoped>
.legal {
  position: relative;
  .background {
    position: fixed;
    display: block;
    top: 0;
    left: 0;
    z-index: 0;
    width: 100%;
    height: 100%;
    // height: 700px;
    background-image: url("/static/img/common/policyBackground.jpg");
    background-repeat: repeat;
    background-size: cover;
    background-position: 50% 0;
  }
  @media only screen and (max-width: 450px) {
    // height: 800px;
  }
  // .background{
  //   position: fixed;
  //   display: block;
  //   top:0;
  //   left: 0;
  //   z-index: 0;
  //   width: 100%;
  //   height: 100%;
  //   background-image: url('/static/img/common/policyBackground.jpg');
  //   background-repeat: no-repeat;
  //   background-size: cover;
  //   background-position: 50% 0;
  // }
  .content_style {
    z-index: 3;
  }
  .titleName {
    font-size: 50px;
    color: #ffffff;
    font-weight: 900;
    text-indent: 0em;
  }
  h2 {
    color: #ffffff;
    margin-bottom: 10px;
  }
  h3 {
    color: #ffffff;
    margin-bottom: 10px;
  }
  p, pre {
    color: #ffffff;
    font-weight: 500;
    white-space: pre-wrap;
    margin-bottom: 10px;
  }
  .code {
    width: 551px;
    max-width: 100%;
  }
}
</style>
